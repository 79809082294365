import React from 'react';

import './Layout.css';

const Layout = (props) => {
    return (
        <div className="parent">
            <header>
                <div className="titleWrapper"><span className="title">Mary Brun</span> Photo Archive</div>
                <div className="navigation">About</div>
            </header>
            <main>{props.children}</main>
            <footer>Footer goes here</footer>
        </div>
    );
}

export default Layout;
