import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";

import Layout from "./components/Layout";
import About from "./components/About";
import Home from "./components/Home";
import PageNotFound from "./components/PageNotFound";

import "./App.css";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <SimpleReactLightbox>
          <Layout>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/about" component={About} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </Layout>
        </SimpleReactLightbox>
      </BrowserRouter>
    );
  }
}
