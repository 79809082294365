import React, { Component } from 'react';

export default class PageNotFound extends Component {
    render() {
        return (
            <div className="content">
                Page Not Found (404)
            </div>
        );
    }
}
