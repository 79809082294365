import React, { Component } from "react";
import RandoPhoto from "shuffle-array";
import InfiniteScroll from "react-infinite-scroll-component";
import ClipLoader from "react-spinners/ClipLoader";
import { SRLWrapper } from "simple-react-lightbox";

import "./Home.css";
const initLoad = 60;
const numbersToLoad = 40;

const options = {
  settings: {
    autoplaySpeed: 2500,
    transitionSpeed: 1000,
  },
  buttons: {
    iconColor: "white",
    showDownloadButton: false,
    showthumbnailsButton: false,
  },
  caption: {
    captionFontFamily: "Montserrat, sans-serif",
    captionFontWeight: "300",
    captionAlignment: "start",
    captionColor: "#FFFFFF",
    captionContainerPadding: "0",
    captionFontSize: "inherit",
    captionFontStyle: "inherit",
    showCaption: true,
  },
  thumbnails: {
    showThumbnails: false,
  },
};

const photoArray = RandoPhoto([
  /* Start */
	{ photoId: '05927', width: 260, height: 341, title: 'Mufta\'s Father', year: '1970s', folder: '5000' },
	{ photoId: '06194', width: 259, height: 165, title: 'Rowing on the Ganges', year: '1964', folder: '6000' },
	{ photoId: '01033', width: 260, height: 409, title: 'Still Water', year: '1970', folder: '1000' },
	{ photoId: '02867', width: 260, height: 393, title: 'Man and boy', year: '1970', folder: '2000' },
	{ photoId: '03745', width: 260, height: 349, title: 'Elder Portrait', year: '1960s', folder: '3000' },
	{ photoId: '03819', width: 260, height: 172, title: 'Family', year: '1970', folder: '3000' },
	{ photoId: '07857', width: 260, height: 174, title: 'Away from it all', year: '1981', folder: '7000' },
	{ photoId: '10109', width: 260, height: 169, title: 'California poppies', year: '1972', folder: '10000' },
	{ photoId: '10272', width: 260, height: 209, title: 'Sleeping Man', year: '1964', folder: '10000' },
	{ photoId: '10833', width: 260, height: 160, title: 'Fern Grotto', year: '1968', folder: '10000' },
	{ photoId: '11745', width: 260, height: 389, title: 'Labbela', year: '1968', folder: '11000' },
	{ photoId: '00779', width: 259, height: 161, title: 'Impalas', year: '1970s', folder: '0000' },
	{ photoId: '01164', width: 259, height: 165, title: 'Tripoli', year: '1960s', folder: '1000' },
	{ photoId: '01546', width: 260, height: 170, title: 'Hyde Park Preacher', year: '1983', folder: '1000' },
	{ photoId: '01989', width: 260, height: 160, title: 'Cave Glow Worms', year: '1970', folder: '1000' },
	{ photoId: '02460', width: 260, height: 297, title: 'Pelican', year: '1980', folder: '2000' },
	{ photoId: '02868', width: 260, height: 366, title: 'Children at Temple Jaipur', year: '1964', folder: '2000' },
	{ photoId: '02872', width: 259, height: 193, title: 'Venice Pigeons', year: '1950s', folder: '2000' },
	{ photoId: '02965', width: 259, height: 162, title: 'Lapp Couple', year: '1950s', folder: '2000' },
	{ photoId: '02971', width: 259, height: 177, title: 'Mapoch Ndebele Village', year: '1960s', folder: '2000' },
	{ photoId: '04514', width: 260, height: 383, title: 'Athabasca Falls', year: '1991', folder: '4000' },
	{ photoId: '05002', width: 260, height: 165, title: 'Ternba Woman', year: '1968', folder: '5000' },
	{ photoId: '05068', width: 259, height: 162, title: 'San Giorgio Maggiore', year: '1970s', folder: '5000' },
	{ photoId: '05421', width: 259, height: 172, title: 'Nothern Star', year: '1970s', folder: '5000' },
	{ photoId: '05663', width: 260, height: 395, title: 'Dakar Woman', year: '1968', folder: '5000' },
	{ photoId: '05666', width: 260, height: 379, title: 'Pagent Attire', year: '1970', folder: '5000' },
	{ photoId: '05673', width: 260, height: 160, title: 'Woman in Catamaran', year: '1970s', folder: '5000' },
	{ photoId: '05674', width: 260, height: 164, title: 'Water Lillies', year: '1991', folder: '5000' },
	{ photoId: '05682', width: 259, height: 164, title: 'After The Rain', year: '1950s', folder: '5000' },
	{ photoId: '05683', width: 260, height: 201, title: 'Beast Of Burden', year: '1970s', folder: '5000' },
	{ photoId: '05890', width: 260, height: 164, title: 'Cloisters', year: '1950s', folder: '5000' },
	{ photoId: '05994', width: 259, height: 227, title: 'Russell Falls 1', year: '1970', folder: '5000' },
	{ photoId: '06096', width: 260, height: 410, title: 'Temple Restoration', year: '1951', folder: '6000' },
	{ photoId: '06192', width: 260, height: 410, title: 'Pleasant Nepalese', year: '1964', folder: '6000' },
	{ photoId: '06198', width: 260, height: 396, title: 'Kids With Kids', year: '1968', folder: '6000' },
	{ photoId: '06206', width: 260, height: 460, title: 'Bombay Woman', year: '1950s', folder: '6000' },
	{ photoId: '06212', width: 260, height: 199, title: 'Grandma And Child', year: '1950s', folder: '6000' },
	{ photoId: '06283', width: 260, height: 177, title: 'Golden Beauties', year: '1960s', folder: '6000' },
	{ photoId: '06448', width: 259, height: 171, title: 'Pattern In Leaf', year: '1981', folder: '6000' },
	{ photoId: '06453', width: 260, height: 164, title: 'Gondolier', year: '1968', folder: '6000' },
	{ photoId: '06700', width: 259, height: 177, title: 'Boat Design', year: '1970s', folder: '6000' },
	{ photoId: '06724', width: 260, height: 165, title: 'Yosemite Valley', year: '1971', folder: '6000' },
	{ photoId: '06967', width: 260, height: 143, title: 'Sailor Coats', year: '1950s', folder: '6000' },
	{ photoId: '07065', width: 260, height: 415, title: 'Pelican', year: '1980', folder: '7000' },
	{ photoId: '07875', width: 260, height: 165, title: 'Jerusalem Sheep', year: '1964', folder: '7000' },
	{ photoId: '08436', width: 260, height: 395, title: 'Garian Vendors', year: '1950s', folder: '8000' },
	{ photoId: '08654', width: 260, height: 164, title: 'Shadow Man', year: '1965', folder: '8000' },
	{ photoId: '08661', width: 260, height: 167, title: 'Monarch', year: '1965', folder: '8000' },
	{ photoId: '08697', width: 259, height: 171, title: 'Cheetahs', year: '1950s', folder: '8000' },
	{ photoId: '10571', width: 260, height: 400, title: 'Burano Lace Maker', year: '1950s', folder: '10000' },
	{ photoId: '10584', width: 259, height: 172, title: 'Purple Iris', year: '1960', folder: '10000' },
	{ photoId: '10693', width: 260, height: 410, title: 'Gray Egret', year: '1970', folder: '10000' },
	{ photoId: '11214', width: 259, height: 172, title: 'Nesting Willet', year: '1971', folder: '11000' },
	{ photoId: '11568', width: 259, height: 160, title: 'Florence Bridges', year: '1968', folder: '11000' },
	{ photoId: '11826', width: 260, height: 385, title: 'Plan de Corones', year: '1971', folder: '11000' },
	{ photoId: '12156', width: 260, height: 170, title: 'Atlantic Puffins', year: '1981', folder: '12000' },
	{ photoId: '12287', width: 259, height: 171, title: 'Boat House', year: '1972', folder: '12000' },
	{ photoId: '12367', width: 259, height: 180, title: 'Sleeping Lion', year: '1960s', folder: '12000' },
	{ photoId: '12833', width: 260, height: 381, title: 'Joshua Tree', year: '1981', folder: '12000' },
	{ photoId: '13794', width: 260, height: 173, title: 'Toledo Pottery', year: '1950s', folder: '13000' },
	{ photoId: '14040', width: 259, height: 174, title: 'Maids', year: '1968', folder: '14000' },
	{ photoId: '14281', width: 259, height: 175, title: 'Lunch', year: '1961', folder: '14000' },
	{ photoId: '00150', width: 260, height: 383, title: 'Galapagos Hawk', year: '1973', folder: '0000' },
	{ photoId: '00296', width: 260, height: 391, title: 'Thai Children', year: '1964', folder: '0000' },
	{ photoId: '00379', width: 260, height: 209, title: 'Athens Flower Market', year: '1964', folder: '0000' },
	{ photoId: '00408', width: 260, height: 174, title: 'Temple of Zeus', year: '1964', folder: '0000' },
	{ photoId: '00719', width: 260, height: 175, title: 'Tree Lined Pond', year: '1990', folder: '0000' },
	{ photoId: '00744', width: 259, height: 176, title: 'Social Weaver Nests', year: '1990', folder: '0000' },
	{ photoId: '00771', width: 259, height: 176, title: 'East African Lion', year: '1990', folder: '0000' },
	{ photoId: '00781', width: 260, height: 200, title: 'Hilgert\'s Vervet Family', year: '1990', folder: '0000' },
	{ photoId: '00806', width: 260, height: 175, title: 'Roadside Gathering', year: '1970', folder: '0000' },
	{ photoId: '00812', width: 260, height: 388, title: 'Mount Hagen Natives', year: '1970', folder: '0000' },
	{ photoId: '00818', width: 260, height: 176, title: 'Ceremonial Music', year: '1970', folder: '0000' },
	{ photoId: '01005', width: 259, height: 135, title: 'Bern Fields', year: '1961', folder: '1000' },
	{ photoId: '01006', width: 260, height: 180, title: 'Altdorf', year: '1961', folder: '1000' },
	{ photoId: '01017', width: 259, height: 175, title: 'Tropic Bliss', year: '1970s', folder: '1000' },
	{ photoId: '01037', width: 260, height: 178, title: 'Franz Josef Glacier', year: '1970', folder: '1000' },
	{ photoId: '01053', width: 260, height: 398, title: 'Reflections', year: '1960s', folder: '1000' },
	{ photoId: '01224', width: 260, height: 398, title: 'Ballerina Flower', year: '1972', folder: '1000' },
	{ photoId: '01228', width: 260, height: 403, title: 'Yosemite Snow Plants', year: '1970s', folder: '1000' },
	{ photoId: '01329', width: 260, height: 367, title: 'Paradise', year: '1970s', folder: '1000' },
	{ photoId: '01361', width: 260, height: 393, title: 'Little Dude', year: '1970s', folder: '1000' },
	{ photoId: '01467', width: 260, height: 351, title: 'Cathedral Tour', year: '1960s', folder: '1000' },
	{ photoId: '01585', width: 260, height: 175, title: 'Hever Castle', year: '1980', folder: '1000' },
	{ photoId: '01692', width: 260, height: 176, title: 'Sydney Grill', year: '1970', folder: '1000' },
	{ photoId: '02065', width: 259, height: 176, title: 'Mount Cook', year: '1970', folder: '2000' },
	{ photoId: '02133', width: 260, height: 176, title: 'Nepalese Children', year: '1964', folder: '2000' },
	{ photoId: '02213', width: 260, height: 175, title: 'Taj Mahal', year: '1964', folder: '2000' },
	{ photoId: '02325', width: 259, height: 174, title: 'Man Sleeping', year: '1990', folder: '2000' },
	{ photoId: '02434', width: 260, height: 393, title: 'Daisies', year: '1990', folder: '2000' },
	{ photoId: '02470', width: 260, height: 173, title: 'Aligator Swimming', year: '1980', folder: '2000' },
	{ photoId: '02503', width: 260, height: 395, title: 'Posing Heron', year: '1980', folder: '2000' },
	{ photoId: '02535', width: 260, height: 390, title: 'Everglades Aligator', year: '1980', folder: '2000' },
	{ photoId: '02842', width: 259, height: 156, title: 'Glacier', year: '1960s', folder: '2000' },
	{ photoId: '02848', width: 259, height: 176, title: 'Family Chores', year: '1970', folder: '2000' },
	{ photoId: '02850', width: 260, height: 366, title: 'Fetching Water', year: '1960s', folder: '2000' },
	{ photoId: '02869', width: 260, height: 346, title: 'Necklace Vendor', year: '1970', folder: '2000' },
	{ photoId: '02870', width: 259, height: 257, title: 'Hello Goat', year: '1950s', folder: '2000' },
	{ photoId: '02889', width: 260, height: 381, title: 'Pond Lillies', year: '1972', folder: '2000' },
	{ photoId: '02899', width: 260, height: 175, title: 'Fern Springs', year: null, folder: '2000' },
	{ photoId: '02926', width: 260, height: 404, title: 'Study In Black White', year: '1960s', folder: '2000' },
	{ photoId: '02963', width: 260, height: 306, title: 'Mother Daughter', year: '1960s', folder: '2000' },
	{ photoId: '02966', width: 259, height: 173, title: 'Elephant', year: '1970s', folder: '2000' },
	{ photoId: '02995', width: 260, height: 392, title: 'Golden Cottonwood', year: '1960s', folder: '2000' },
	{ photoId: '03009', width: 260, height: 392, title: 'Monarch', year: '1963', folder: '3000' },
	{ photoId: '03057', width: 260, height: 389, title: 'Life In Dakar', year: '1968', folder: '3000' },
	{ photoId: '03062', width: 260, height: 386, title: 'Flower Vendor', year: '1968', folder: '3000' },
	{ photoId: '03101', width: 260, height: 172, title: 'Dogwood Blossom', year: '1970s', folder: '3000' },
	{ photoId: '03117', width: 260, height: 380, title: 'Winter Wonderland', year: '1970s', folder: '3000' },
	{ photoId: '03140', width: 259, height: 166, title: 'Nazare Boats', year: '1968', folder: '3000' },
	{ photoId: '03160', width: 260, height: 188, title: 'Bora Bora Boats', year: '1970', folder: '3000' },
	{ photoId: '03165', width: 259, height: 189, title: 'Tropical Sunset', year: '1970', folder: '3000' },
	{ photoId: '03203', width: 260, height: 399, title: 'Porter', year: '1970', folder: '3000' },
	{ photoId: '03342', width: 259, height: 172, title: 'Russell Falls', year: '1970', folder: '3000' },
	{ photoId: '03371', width: 260, height: 175, title: 'Snack Time', year: '1970', folder: '3000' },
	{ photoId: '03520', width: 260, height: 175, title: 'Day In The Life', year: '1985', folder: '3000' },
	{ photoId: '03632', width: 260, height: 175, title: 'Farm Entrance', year: '1985', folder: '3000' },
	{ photoId: '03760', width: 260, height: 310, title: 'Witch Doctor', year: '1960s', folder: '3000' },
	{ photoId: '03777', width: 260, height: 395, title: 'Mount Hagen Shopper', year: '1970', folder: '3000' },
	{ photoId: '03782', width: 260, height: 388, title: 'Village Hut', year: '1970', folder: '3000' },
	{ photoId: '03783', width: 260, height: 176, title: 'Roadside Natives', year: '1970', folder: '3000' },
	{ photoId: '03789', width: 259, height: 177, title: 'Nursing Mother', year: '1970', folder: '3000' },
	{ photoId: '03790', width: 259, height: 160, title: 'Women Gathering', year: '1970', folder: '3000' },
	{ photoId: '03801', width: 260, height: 393, title: 'Mount Hagen Man 1', year: '1970', folder: '3000' },
	{ photoId: '03803', width: 260, height: 392, title: 'Mount Hagen Man 2', year: '1970', folder: '3000' },
	{ photoId: '03807', width: 260, height: 335, title: 'Mount Hagen Man 3', year: '1970', folder: '3000' },
	{ photoId: '03809', width: 260, height: 393, title: 'Mount Hagen Woman 1', year: '1970', folder: '3000' },
	{ photoId: '03836', width: 259, height: 176, title: 'Elder Gathering', year: '1970', folder: '3000' },
	{ photoId: '03854', width: 260, height: 393, title: 'Mount Hagen Man 4', year: '1970', folder: '3000' },
	{ photoId: '04202', width: 259, height: 177, title: 'St Basil\'s Cathedral', year: '1983', folder: '4000' },
	{ photoId: '04306', width: 259, height: 188, title: 'Boats Large And Small', year: '1981', folder: '4000' },
	{ photoId: '04308', width: 260, height: 392, title: 'Boat Reflection', year: '1981', folder: '4000' },
	{ photoId: '04324', width: 260, height: 187, title: 'Sod Houses', year: '1981', folder: '4000' },
	{ photoId: '04384', width: 260, height: 312, title: 'Hungry Puffin 1', year: '1981', folder: '4000' },
	{ photoId: '04385', width: 260, height: 368, title: 'Hungry Puffin 2', year: '1981', folder: '4000' },
	{ photoId: '04545', width: 259, height: 175, title: 'Peyto Peak', year: '1995', folder: '4000' },
	{ photoId: '04640', width: 260, height: 176, title: 'Swimming Pelicans', year: '1969', folder: '4000' },
	{ photoId: '04695', width: 260, height: 389, title: 'Relaxing', year: '1969', folder: '4000' },
	{ photoId: '04704', width: 259, height: 176, title: 'Sheep', year: '1995', folder: '4000' },
	{ photoId: '04719', width: 259, height: 175, title: 'Swans', year: '1995', folder: '4000' },
	{ photoId: '04927', width: 260, height: 179, title: 'Street Market', year: '1968', folder: '4000' },
	{ photoId: '04977', width: 260, height: 175, title: 'Bike Ride', year: '1968', folder: '4000' },
	{ photoId: '04982', width: 260, height: 389, title: 'Market Day', year: '1968', folder: '4000' },
	{ photoId: '04990', width: 259, height: 176, title: 'Status Beads', year: '1968', folder: '4000' },
  /* End */
]);
export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: photoArray.slice(0, initLoad),
      hasMore: true,
    };
  }

  fetchMoreData = () => {
    if (this.state.items.length >= photoArray.length) {
      this.setState({ hasMore: false });
      return;
    }

    setTimeout(() => {
        this.setState((prev) => (
          {
            items: prev.items.concat(
              photoArray.slice(
                prev.items.length,
                prev.items.length + numbersToLoad)
            )
          }
        ));
    }, 1000);
  };

  fetchPhotoTitle(photo) {
    var title = photo.title;
    if (photo.year) {
        title = title + ' (' + photo.year + ')';
    }
    return title;
  }

  render() {
    return (
      <InfiniteScroll
        dataLength = { this.state.items.length }
        next = { this.fetchMoreData }
        hasMore = { this.state.hasMore }
        loader = {<div className="loader"><ClipLoader loading={this.state.hasMore} /></div> }
      >
        <SRLWrapper options={options}>
          <div className="masonry"> { this.state.items.map((photo) => (
            <div key={photo.photoId} width={photo.width} height={photo.height} className="item">
                <a href={require('../photos/' + photo.folder + '/' + photo.photoId + '.jpg')} data-attribute="SRL">
                  <img className="image" src={require('../photos/' + photo.folder + '/' + photo.photoId + 't.jpg')} alt={this.fetchPhotoTitle(photo)} />
                  <div className="viewOnHover">{this.fetchPhotoTitle(photo)}</div>
                </a>
            </div>
            )) }
          </div>
        </SRLWrapper>
      </InfiniteScroll>
    );
  }
}
